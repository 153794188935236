<template>
  <div>
    <el-form size="medium" label-width="auto">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-form-item :label="$t('employee.company')">
            <el-select v-model="queryParam.companyId" :placeholder="$ts('placeholder.select', 'employee.company')" style="width: 100%" clearable>
              <template v-for="company in companyList">
                <el-option :value="company.id" :label="company.name"/>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('employee.account')">
            <el-input v-model.trim="queryParam.account" :placeholder="$ts('placeholder.input', 'employee.account')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('employee.name')">
            <el-input v-model.trim="queryParam.name" :placeholder="$ts('placeholder.input', 'employee.name')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item :label="$t('employee.realName')">
            <el-input v-model.trim="queryParam.realName" :placeholder="$ts('placeholder.input', 'employee.realName')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label-width="0">
            <el-button type="primary" @click="handleSearch(queryParam)">{{$t('button.search')}}</el-button>
            <el-button type="primary" @click="handleSearch({})">{{$t('button.reset')}}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import {list as getCompanyList} from '@/api/company'

export default {
  name: "EmployeeListSearchBox",
  model: {
    prop: 'queryParam',
    event: 'change'
  },
  props: {
    queryParam: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  
  data() {
    return {
      companyList: []
    }
  },
  
  created() {
    getCompanyList({page: 1, pageSize: 999999}).then(res => {
      this.companyList = res.data.list
    })
  },
  
  methods: {
    handleSearch(queryParam) {
      this.$emit('change', queryParam)
      this.$nextTick(() => {
        this.$emit('onSearch')
      })
    },
  },
}
</script>
<style scoped>
</style>