<template>
  <span style="text-align: center">
    <el-button size="mini" type="primary" @click="start(id)">{{id ? $t('button.edit') : $t('button.add')}}</el-button> &nbsp;
    <el-dialog
      :title="id ? $t('button.edit') : $t('button.add')"
      :visible.sync="dialogVisible"
      width="400px">
      <div v-loading="loading">
        <el-form
          ref="form"
          :rules="rules"
          :model="user"
          label-width="auto"
          size="medium">
          <el-form-item :label="$t('employee.company')" prop="companyId">
            <el-select v-model="user.companyId" :placeholder="$ts('placeholder.select', 'employee.company')" style="width: 100%">
              <template v-for="company in companyList">
                <el-option :value="company.id" :label="company.name"/>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('employee.account')" prop="account">
            <el-input v-model="user.account" :placeholder="$ts('placeholder.input', 'employee.account')"/>
          </el-form-item>
          <el-form-item :label="$t('employee.password')" prop="password">
            <el-input v-model="user.password" :placeholder="$ts('placeholder.input', 'employee.password')"/>
          </el-form-item>
          <el-form-item :label="$t('employee.name')" prop="name">
            <el-input v-model="user.name" :placeholder="$ts('placeholder.input', 'employee.name')"/>
          </el-form-item>
          <el-form-item :label="$t('employee.realName')" prop="realName">
            <el-input v-model="user.realName" :placeholder="$ts('placeholder.input', 'employee.realName')"/>
          </el-form-item>
          <el-form-item :label="$t('employee.sort')" prop="sort">
            <el-input v-model="user.sort" :placeholder="$ts('placeholder.input', 'employee.sort')"/>
          </el-form-item>
        </el-form>
      </div>
      <template slot="footer">
        <el-button size="medium" type="primary" @click="onSubmit" :loading="setIng">{{$t('button.confirm')}}</el-button>
        <el-button size="medium" @click="dialogVisible = false">{{$t('button.cancel')}}</el-button>
      </template>
    </el-dialog>
  </span>
</template>
<script>
import {detail, save} from '@/api/user'
import {list as getCompanyList} from '@/api/company'
import {showSuccessMsg} from "@/utils/func";

export default {
  name: "SaveEmployeeButton",
  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      user: {
        type: 2,
        companyId: 0,
        sort: 0
      },
      companyList: [],
      rules: {
        
        companyId: [
          {required: true, message: this.$ts('placeholder.select', 'employee.company'), trigger: 'blur'},
        ],
        
        account: [
          {required: true, message: this.$ts('placeholder.input', 'employee.account'), trigger: 'blur'},
        ],
        
        password: [
          {required: true, message: this.$ts('placeholder.input', 'employee.password'), trigger: 'blur'},
        ],
        
        name: [
          {required: true, message: this.$ts('placeholder.input', 'employee.name'), trigger: 'blur'},
        ],
        
        realName: [
          {required: true, message: this.$ts('placeholder.input', 'employee.realName'), trigger: 'blur'},
        ],
        
        sort: [
          {required: true, message: this.$ts('placeholder.input', 'employee.sort'), trigger: 'blur'},
        ]
        
      },
      
      setIng: false,
    }
  },
  methods: {
    start(id) {
      this.dialogVisible = true
      this.loading = false
      this.user = {
        type: 2,
        companyId: 0,
        sort: 0
      }
      this.setIng = false
      this.getInfo(id)
    },
    getInfo(id) {
      this.loading = true
      Promise.allSettled([
        id ? detail({id}).then(res => res.data).catch(() => this.user) : Promise.resolve(this.user),
        getCompanyList({page: 1, pageSize: 999999}).then(res => res.data.list).catch(() => [])
      ]).then(res => {
        let [{value: user}, {value: companyList}] = res
        this.user = user
        this.companyList = companyList
        if (!user.companyId && companyList.length) {
          user.companyId = companyList[0].id
        }
      }).finally(() => {
        setTimeout(() => {
          this.loading = false
        }, 500)
      })
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          
          this.setIng = true
          let data = JSON.parse(JSON.stringify(this.user))
          save(data).then(() => {
            this.dialogVisible = false
            showSuccessMsg(this.$t('message.title'), (this.user.id ? this.$t('message.editSuccess') : this.$t('message.addSuccess')))
            this.$emit('xzdListPageReload', !this.user.id)
          }).finally(() => {
            setTimeout(() => {
              this.setIng = false
            }, 500)
          })
        }
      });
    }
  }
}
</script>
<style scoped>
</style>